import React from "react";
import Modal from "components/antdCustom/Modal";
import ModalStyled from "./modalStyled";

const ViewReceiptModal = ({ visible, onCancel, image }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} centered footer={null} width={595} bodyStyle={{ height: "90vh" }}>
      <ModalStyled>
        <div style={{ padding: 24, textAlign: "center" }}>
          <img src={image} alt="receipt" style={{ objectFit: "contain", width: "70%" }} />
        </div>
      </ModalStyled>
    </Modal>
  );
};

export default ViewReceiptModal;
