import styled from "styled-components";

const AgentListingStyled = styled.div`
  width: 100%;
  height: 100%;

  .sales-statistic-container {
    padding: 24px 20px 24px 20px;
    background-color: #fff;
    margin-bottom: 24px;
    border-radius: 4px;

    .amount {
      font-size: 20px;
      font-weight: 700;
      color: #303030;
    }
  }

  .chip {
    padding: 6px 16px 6px 16px;
    border-radius: 100px;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
  }

  .button {
    width: 100px;
  }

  @media screen and (max-width: 991px) {
    .button {
      .ant-form-item-label {
        display: none;
      }
    }
  }
`;

export default AgentListingStyled;
