import React from "react";
import { Layout } from "antd";
import FilterBar from "./FilterBar";
import TableFilteredOrders from "./TableFilteredOrders";

const { Content } = Layout;

const FilteredOrdersList = ({ category, loading, storeList, resData, setResData, params, setParams, form }) => {
  const handleTableChange = (pagination) => {
    setParams({ ...params, pagination });
  };

  const handleFilterChange = (filter) => {
    let { pagination } = params;

    pagination = { ...pagination, current: 1 };
    //set page to 1 when change filter
    setParams({ ...params, filter, pagination });
  };

  const { filter, pagination } = params;

  return (
    <Content
      style={{
        padding: 20,
        background: "white",
      }}
    >
      <FilterBar
        value={filter}
        onChange={handleFilterChange}
        setResData={setResData}
        loading={loading}
        storeList={storeList}
        form={form}
      />
      <TableFilteredOrders
        dataSource={resData?.data}
        pagination={{ ...pagination, total: resData?.total }}
        loading={loading}
        category={category}
        onChange={handleTableChange}
      />
    </Content>
  );
};

export default FilteredOrdersList;
