import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FEE_TYPE } from "src/variables";

const useStoreColumns = (onClickSendInvitationEmail, itemsLoadingSendEmail) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t("id"),
        key: "id",
        render: (rowData) => {
          const locationInitials = rowData.store_name
            .split(" ")
            .map(([initial]) => initial.toUpperCase())
            .join("");
          return (
            <div
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 15,
                paddingBottom: 15,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "inline-flex",
                }}
              >
                <a
                  href={"/stores/" + rowData.id}
                  style={{
                    color: "#1551B0",
                    fontSize: 14,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {rowData.id}
                </a>
                <div
                  style={{ justifyContent: "flex-start", alignItems: "flex-start", gap: 10, display: "inline-flex" }}
                >
                  <div
                    style={{
                      color: "#505050",
                      fontSize: 14,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    {locationInitials}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: t("nameEmailAddress"),
        key: "email",
        width: "320px",
        render: (rowData) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#505050",
                  fontSize: "14px",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {rowData.store_name}
              </div>
              <div
                style={{
                  color: "#6C757D",
                  fontSize: "14px",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                {rowData.email}
              </div>
            </div>
          );
        },
      },
      {
        title: t("address"),
        dataIndex: "address",
        key: "address",
        width: "296px",
      },
      {
        title: t("fee"),
        dataIndex: "active_fee",
        key: "active_fee",
        align: "center",
        render: (active_fee) => {
          return (
            active_fee && (
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: active_fee.feeTiers
                        ?.map((tier, idx) => {
                          const displayFeeValue =
                            tier.feeType === FEE_TYPE.PERCENTAGE ? `${tier.feeValue * 100}%` : `RM ${tier.feeValue} `;
                          const maxTierAmount =
                            idx <= active_fee.feeTiers.length - 2
                              ? active_fee.feeTiers[idx + 1].minTierAmount - 0.01
                              : "";
                          return idx === active_fee.feeTiers.length - 1
                            ? `<span>From RM ${tier.minTierAmount}</span>  <span style="text-align:right; float:right;margin-left:10px">${displayFeeValue}</span><br>`
                            : `<span>RM ${tier.minTierAmount}  - RM ${maxTierAmount}</span> <span style="text-align:right; float:right;margin-left:10px">${displayFeeValue}</span><br>`;
                        })
                        .join(""),
                    }}
                  />
                }
                overlayStyle={{ whiteSpace: "pre-line" }}
              >
                <div
                  style={{
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    margin: "auto 0",
                    background: active_fee.type === "global" ? "#E1EFFF" : "#F7E2FF",
                    borderRadius: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      color: active_fee.type === "global" ? "#1077ED" : "#8E44AD",
                      fontSize: "14px",
                      fontWeight: "600",
                      wordWrap: "break-word",
                      textAlign: "right", // Aligning to the most right
                    }}
                  >
                    {active_fee.type === "global" ? "Default" : "Custom"}
                  </div>
                </div>
              </Tooltip>
            )
          );
        },
      },
      {
        title: t("action"),
        key: "action",
        width: "210px",
        align: "center",
        resizable: false,
        render: (rowData) => {
          const { is_active = true } = rowData;
          if (!is_active) return null;

          const isLoadingSendMail = itemsLoadingSendEmail.includes(rowData.id);
          return (
            <>
              <Button
                loading={isLoadingSendMail}
                disabled={isLoadingSendMail}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickSendInvitationEmail(rowData);
                }}
              >
                {t("sendInvitationEmail")}
              </Button>
              <Button
                style={{ marginTop: 10, borderColor: "#3DED97" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Link to={`stores/${rowData.id}/fee-list`}>{t("manageFees")}</Link>
              </Button>
            </>
          );
        },
      },
    ],
    [onClickSendInvitationEmail, itemsLoadingSendEmail, t]
  );
};

export default useStoreColumns;
