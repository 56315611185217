import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import useAPILoading from "hooks/useAPILoading";
import { createStore, sendInvitationToStore } from "APIService/store";
import FormStoreDetail from "./components/FormStoreDetail";
import stickyNotification from "components/antdCustom/notification/stickyNotification.js";

const CreateStore = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const history = useHistory();
  const [requestCreateStore, loading] = useAPILoading(createStore);
  let storeDefault = {
    is_active: true,
    is_available: false,
    store_country_code: "+60",
    booking_url: null,
    is_3rd_saturday_off: false,
    is_public_holiday_off: false,
    is_voucher_on: true,
  };

  if (currentPath === "/agents/create") {
    storeDefault = { ...storeDefault, kiosk_type: "agent" };
  }

  const onCreateStore = (store) => {
    const { is_active, ...transformedData } = store;
    requestCreateStore(transformedData).then(() => {
      const closeNotification = stickyNotification.success({
        icon: <LoadingOutlined />,
        message: t("objSendInvitation.sendingEmail"),
        placement: "bottomRight",
        style: { background: "#ffeba8", color: "#7a6b2a" },
      });
      sendInvitationToStore(store.email)
        .then(() => {
          closeNotification();
          notification.success({
            message: t("objSendInvitation.success"),
            description: t("emailSentNotification")(store.email),
            duration: 7,
            placement: "bottomRight",
          });
        })
        .catch(() => {
          notification.error({
            message: t("objSendInvitation.sendEmailFail"),
            placement: "bottomRight",
          });
        })
        .finally(() => {
          history.goBack();
        });
    });
  };

  return (
    <div>
      <h1 style={{ paddingLeft: "2vw" }}> {t("createStore")} </h1>
      <FormStoreDetail onSubmit={onCreateStore} loading={loading} store={storeDefault} />
    </div>
  );
};

export default CreateStore;
