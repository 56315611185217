import React from "react";
import Modal from "components/antdCustom/Modal";
import ModalStyled from "./modalStyled";
import { Button, Col, Row, Space } from "antd";
import moment from "moment";
import { formatCurrency } from "src/utils";
import { getStatusText } from "../TableCredit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";

const CreditDetailsModal = ({ visible, onCancel, data, handleRedirectToTransaction, handleOpenReceipt }) => {
  const status = getStatusText(data?.type);
  const handleOnClick = () => {
    onCancel();
    if (data?.type === "out") handleRedirectToTransaction();
    if (data?.type === "in") handleOpenReceipt();
  };
  return (
    <Modal visible={visible} onCancel={onCancel} centered footer={null} width={595}>
      <ModalStyled>
        <div style={{ padding: 4 }}>
          <p className="credit-details-title">Credit details</p>
          <div className="details-container">
            <Row justify="space-between" className="top-section">
              <div>
                <p className="transaction-id">
                  Transaction ID: #{data?.type === "in" ? data?.id : data?.transaction_id}
                </p>
                <p className="transaction-amount">
                  RM
                  {formatCurrency(
                    data?.type === "in"
                      ? (data?.amount - data?.total_agent_service_fee).toFixed(2)
                      : data?.amount.toFixed(2)
                  )}
                </p>
                <p className="transaction-date">{moment.utc(data?.created_on).format("DD MMM YYYY, HH:mmA")}</p>
              </div>
              <div>
                <div
                  className="chip"
                  style={{
                    backgroundColor: status.backgroundColor,
                    color: status.textColor,
                  }}
                >
                  {status.textStatus}
                </div>
              </div>
            </Row>
            <div>
              <div>
                <p className="transaction-date">FROM</p>
                <p className="store-name">{data?.type === "in" ? "DAPAT Vista Sdn Bhd" : data?.merchant?.store_name}</p>
              </div>
              <div className="client-details">
                <p className="transaction-date">TO</p>
                {data?.type === "in" ? (
                  <p className="store-name">{data?.merchant?.store_name}</p>
                ) : (
                  <div>
                    <div className="client-details">
                      <Space size={"middle"}>
                        <FontAwesomeIcon icon={faUser} color="#6C757D" className="icon" />
                        <p>{data?.client_details?.client_full_name}</p>
                      </Space>
                    </div>
                    <div className="client-details">
                      <Space size={"middle"}>
                        <FontAwesomeIcon icon={faPhone} color="#6C757D" className="icon" />
                        <p>{data?.client_details?.client_countrycode + data?.client_details?.client_phone}</p>
                      </Space>
                    </div>
                    <div className="client-details">
                      <Space size={"middle"}>
                        <FontAwesomeIcon icon={faEnvelope} color="#6C757D" className="icon" />
                        <p>{data?.client_details?.client_email}</p>
                      </Space>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {data?.type === "in" ? (
            <div className="admin-details-container">
              <p className="title">Transferred by (HQ)</p>
              <p className="email">{data?.admin_details?.email}</p>
            </div>
          ) : null}
          <div style={{ overflow: "hidden", marginTop: 16 }}>
            <Row gutter={[8, 10]} className="button-row">
              <Col xs={24} sm={12}>
                <Button type="default" className="button" size="large" onClick={onCancel}>
                  Close
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button type="primary" className="button" size="large" onClick={handleOnClick}>
                  {data?.type === "in" ? "View receipt" : "View transaction"}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalStyled>
    </Modal>
  );
};

export default CreditDetailsModal;
