import React, { useState } from "react";
import { Button, Col, Form, InputNumber, Row, Select, Upload, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";
import useAPILoading from "hooks/useAPILoading";
import { transferCredit } from "APIService/store";
import ModalTransferCreditStyled from "./styled";

const { Option } = Select;
const { Dragger } = Upload;

const ModalTransferCredit = ({ openModalTransferCredit, setOpenModalTransferCredit, agentList, onChange }) => {
  const { t } = useTranslation();
  const [callTransferCredit, loadingTransferCredit] = useAPILoading(transferCredit);
  const [form] = Form.useForm();
  const [state, setState] = useState({
    creditAmount: 0,
    selectedImage: null,
    showDragger: false,
  });

  const handleAmountChange = (value) => {
    const newCreditAmount = parseFloat(value * 0.98).toFixed(2);
    setState((prevState) => ({ ...prevState, creditAmount: newCreditAmount }));
  };

  const handleUploadReceipt = async (image) => {
    const formData = new FormData();
    formData.append("receipt", image);
    formData.append("store_id", form.getFieldValue("store_id"));
    formData.append("amount", form.getFieldValue("amount"));

    try {
      callTransferCredit(formData)
        .then((res) => {
          if (res.status === 200) {
            resetFormAndCloseModal();
            notification.success({ message: t("transferCreditSuccess"), placement: "bottomRight" });
          }
        })
        .finally(() => {
          onChange();
        });
    } catch (error) {
      console.error("Transfer credit submission failed:", error);
    }
  };

  const handleImageChange = (info) => {
    if (info.fileList.length) {
      const uploadedFile = info.fileList[info.fileList.length - 1].originFileObj;
      setState((prevState) => ({ ...prevState, selectedImage: uploadedFile }));
    }
  };

  const resetFormAndCloseModal = () => {
    form.resetFields();
    setState({ creditAmount: 0, selectedImage: null, showDragger: false });
    setOpenModalTransferCredit(false);
  };

  const toggleDragger = () => {
    form
      .validateFields()
      .then(() => {
        setState((prevState) => ({ ...prevState, showDragger: !prevState.showDragger }));
      })
      .catch((errorInfo) => console.log("Form validation failed:", errorInfo));
  };

  return (
    <Modal
      visible={openModalTransferCredit}
      onCancel={resetFormAndCloseModal}
      footer={
        !state.showDragger && (
          <Row gutter={16}>
            <Col span={12}>
              <Button style={{ height: 44 }} onClick={resetFormAndCloseModal} block>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ height: 44 }}
                loading={loadingTransferCredit}
                type="primary"
                onClick={toggleDragger}
                block
              >
                <FontAwesomeIcon icon={faCamera} style={{ marginRight: 8 }} /> Upload Receipt
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <ModalTransferCreditStyled>
        {!state.showDragger ? (
          <>
            <h2>Add credit</h2>
            <Form form={form} labelCol={{ span: 24 }}>
              <Form.Item
                name="store_id"
                label={t("agentName")}
                rules={[{ required: true, message: "Please choose an agent." }]}
              >
                <Select
                  showSearch
                  placeholder="Select an agent"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {agentList &&
                    agentList.map((agent) => (
                      <Option key={agent.value} value={agent.value}>
                        {agent.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("topUpAmount")}
                name="amount"
                rules={[{ required: true, message: "Please enter transfer amount." }]}
              >
                <InputNumber style={{ width: "100%" }} onChange={handleAmountChange} />
              </Form.Item>
              <Form.Item label={t("creditAmount")}>
                <InputNumber disabled style={{ width: "100%" }} value={state.creditAmount} />
              </Form.Item>
              <Form.Item label={t("paymentMethod")} name="paymentMethod">
                <Select defaultValue="Bank Transfer">
                  <Option value="Bank Transfer">Bank Transfer</Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            {state.selectedImage ? (
              <div style={{ textAlign: "center" }}>
                <img
                  src={URL.createObjectURL(state.selectedImage)}
                  alt="Selected"
                  style={{ maxWidth: "100%", maxHeight: "400px", margin: "20px auto" }}
                />
                <Row gutter={16}>
                  <Col span={12}>
                    <Button block onClick={() => setState((prevState) => ({ ...prevState, selectedImage: null }))}>
                      Use different image
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button block type="primary" onClick={() => handleUploadReceipt(state.selectedImage)}>
                      Use this image
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <h2 style={{ textAlign: "center", marginBottom: 25 }}>Upload receipt</h2>
                <Dragger
                  style={{ padding: 24 }}
                  onChange={handleImageChange}
                  showUploadList={false}
                  accept=".jpg,.jpeg,.png"
                  beforeUpload={() => false}
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <FontAwesomeIcon icon={faImage} style={{ color: "#9C9C9C", fontSize: 45 }} />
                    </p>
                    <p className="upload-text" style={{ marginTop: 16, marginBottom: 16 }}>
                      Browse and upload the image you want to upload from your computer
                    </p>
                    <div className="browse-container">
                      <div className="browse-button">Browse image</div>
                    </div>
                  </div>
                </Dragger>
              </>
            )}
          </>
        )}
      </ModalTransferCreditStyled>
    </Modal>
  );
};

export default ModalTransferCredit;
