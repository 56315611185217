import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "src/components/antdCustom/Table";
import { STATUS_TEXT } from "./constant";
import CreditDetailsModal from "./components/CreditDetailsModal";
import moment from "moment";
import ViewReceiptModal from "./components/ViewReceiptModal";

export const getStatusText = (status) => {
  const data = STATUS_TEXT[status];
  return !data ? {} : data;
};

const getColumnsTranslated = (setSelectedTransaction, setOpenCreditModal) => [
  {
    title: "Reference ID",
    key: "id",
    fixed: "left",
    render: (record) => (
      <p
        style={{
          fontSize: 14,
          marginBottom: 0,
          color: record.payment_status === "Cancelled" ? "#D9534F" : "#1551B0",
          textDecorationLine: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          setSelectedTransaction(record);
          setOpenCreditModal(true);
        }}
      >
        #{record.id}
      </p>
    ),
    width: "150px",
  },
  {
    title: "Date",
    dataIndex: "created_on",
    key: "created_on",
    render: (value) => moment.utc(value).format("DD/MM/YYYY"),
  },
  {
    title: "Agent",
    dataIndex: "merchant",
    key: "merchant",
    render: (value) => (
      <p
        style={{
          fontSize: 14,
          marginBottom: 0,
          color: "#1551B0",
          cursor: "pointer",
        }}
      >
        {value.store_name}
      </p>
    ),
  },
  {
    title: "Type",
    key: "type",
    render: (record) => {
      const status = getStatusText(record.type);
      return (
        <div
          className="chip"
          style={{
            backgroundColor: status.backgroundColor,
            color: status.textColor,
          }}
        >
          {status.textStatus}
        </div>
      );
    },
  },
  {
    title: "Amount (RM)",
    key: "amount",
    render: (record) => {
      if (record.amount) {
        const amount =
          record.type === "in" ? (record.amount + (record.service_charge || 0)).toFixed(2) : record.amount.toFixed(2);
        return `${record.type === "in" ? "+" : "-"}${amount}`;
      }
    },
  },
  {
    title: "Credit Amount (RM)",
    key: "amount",
    render: (record) => `${record.type === "in" ? "+" : "-"}${record.amount.toFixed(2)}`,
  },
  {
    title: "Balance (RM)",
    dataIndex: "current_credit",
    key: "current_credit",
    render: (value) => value && value.toFixed && value.toFixed(2),
  },
];

const TableCredit = (props) => {
  const { dataSource, loading, onChange, pagination } = props;
  const history = useHistory();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [openReceiptModal, setOpenReceiptModal] = useState(false);

  const columns = useMemo(
    () => getColumnsTranslated(setSelectedTransaction, setOpenCreditModal),
    [setSelectedTransaction, setOpenCreditModal]
  );

  const handleRedirectToTransaction = () => {
    history.push("/transaction-history");
  };

  const handleOpenReceipt = () => {
    setOpenReceiptModal(true);
  };

  return (
    <>
      <CreditDetailsModal
        data={selectedTransaction}
        visible={openCreditModal}
        onCancel={() => setOpenCreditModal(false)}
        handleRedirectToTransaction={handleRedirectToTransaction}
        handleOpenReceipt={handleOpenReceipt}
      />
      <ViewReceiptModal
        visible={openReceiptModal}
        onCancel={() => setOpenReceiptModal(false)}
        image={selectedTransaction?.receipt_url}
      />
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        scroll={{ x: 1100, y: "calc(100vh - 320px)" }}
      />
    </>
  );
};

export default TableCredit;
