import React from "react";
import { Col, Row, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faCheckCircle, faRedo, faShoppingBasket, faWallet } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "src/utils";

const paymentMethodText = {
  fpx: "FPX - Online Banking",
  cash: "Cash",
  qr: "QR Payment - DuitNow QR",
  jompay: "Kocek Teller Machine - ATM JomPAY",
  mypay: "MyPay",
  card: "Credit/ Debit Card",
  pay_later: "PayLater by KOCEK",
};

const getPaymentMethodText = (payment_method) => {
  return paymentMethodText[payment_method];
};

const OrderLog = ({ loading, activityLogs }) => {
  return (
    <Spin spinning={loading}>
      {activityLogs && activityLogs.length > 0 ? (
        <div className="logs">
          <div className="logs-details">
            {activityLogs?.map((item) => {
              const paymentMethod = getPaymentMethodText(item.payment_details.payment_method);
              return (
                <>
                  <Row className="logs-container">
                    <FontAwesomeIcon
                      icon={
                        item.action_type === 1
                          ? faShoppingBasket
                          : item.action_type === 2 || item.action_type === 3 || item.action_type === 4
                          ? faWallet
                          : item.action_type === 5
                          ? faRedo
                          : item.action_type === 6
                          ? faBox
                          : item.action_type === 7 || item.action_type === 8
                          ? faCheckCircle
                          : null
                      }
                      color="#6C757D"
                      style={{ marginRight: 8, marginTop: 3, width: 14 }}
                    />
                    <Col style={{ flex: 1 }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <p className="logs-action">{item.action}</p>
                      </div>
                      <p className="logs-date">{moment(item.created_on).format("DD/MM/YYYY, hh:mm A")}</p>
                      <div className="logs-details-container">
                        {item.action_type === 1 ? (
                          <>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16 }}
                            >
                              <Col xs={8}>
                                <p>Ordered via</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.order.order_type === "Mobile" ? "Online" : item.order.order_type}</p>
                              </Col>
                            </Row>
                            <Row className="logs-detail-top-column" style={{ paddingTop: 16 }}>
                              <Col xs={8}>
                                <p>Performed by</p>
                              </Col>
                              <Col xs={16}>
                                <p>
                                  {item.userDetails.name} ({item.userDetails.email})
                                </p>
                              </Col>
                            </Row>
                          </>
                        ) : null}

                        {item.action_type === 2 || item.action_type === 3 || item.action_type === 4 ? (
                          <>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16 }}
                            >
                              <Col xs={8}>
                                <p>Amount</p>
                              </Col>
                              <Col xs={16}>
                                <p>
                                  RM {parseFloat(formatCurrency(item.payment_details.payment_total_amount)).toFixed(2)}
                                </p>
                              </Col>
                            </Row>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                            >
                              <Col xs={8}>
                                <p>Payment method</p>
                              </Col>
                              <Col xs={16}>
                                <p>{paymentMethod}</p>
                              </Col>
                            </Row>
                            {item.order.bank_name ? (
                              <Row
                                className="logs-detail-top-column"
                                style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                              >
                                <Col xs={8}>
                                  <p>Bank name</p>
                                </Col>
                                <Col xs={16}>
                                  <p>{item.order.bank_name}</p>
                                </Col>
                              </Row>
                            ) : null}
                            <Row
                              className="logs-detail-top-column"
                              style={{
                                paddingTop: 16,
                                paddingBottom: item.payment_details.payment_receipt_url && 16,
                                borderBottom: item.payment_details.payment_receipt_url && "1px solid #D9D9D9",
                              }}
                            >
                              <Col xs={8}>
                                <p>Status</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.new_status}</p>
                              </Col>
                            </Row>
                            {item.payment_details.payment_receipt_url ? (
                              <Row className="logs-detail-top-column" style={{ paddingTop: 16 }}>
                                <Col xs={8}>
                                  <p>Attachment</p>
                                </Col>
                                <Col xs={16}>
                                  <a
                                    href={item.payment_details.payment_receipt_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p className="payment-receipt">{item.payment_details.payment_number}</p>
                                  </a>
                                </Col>
                              </Row>
                            ) : null}
                          </>
                        ) : null}

                        {item.action_type === 5 ? (
                          <>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16 }}
                            >
                              <Col xs={8}>
                                <p>Updated</p>
                              </Col>
                              <Col xs={16}>
                                <p>
                                  Status: {item.old_status} → {item.new_status}
                                </p>
                              </Col>
                            </Row>
                            <Row className="logs-detail-top-column" style={{ paddingTop: 16 }}>
                              <Col xs={8}>
                                <p>Performed by</p>
                              </Col>
                              <Col xs={16}>
                                <p>
                                  {item.userDetails.name} ({item.userDetails.email})
                                </p>
                              </Col>
                            </Row>
                          </>
                        ) : null}

                        {item.action_type === 6 ? (
                          <>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16 }}
                            >
                              <Col xs={8}>
                                <p>Ordered via</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.order.order_type === "Mobile" ? "Online" : item.order.order_type}</p>
                              </Col>
                            </Row>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                            >
                              <Col xs={8}>
                                <p>Packed item</p>
                              </Col>
                              <Col xs={16}>
                                {item.order.order_details.map((product, index) => {
                                  return (
                                    <p style={index === 0 ? null : { marginTop: 16 }}>
                                      {product.product_quantity} x {product.product_name} (RM{" "}
                                      {parseFloat(formatCurrency(product.product_total_amount)).toFixed(2)})
                                    </p>
                                  );
                                })}
                              </Col>
                            </Row>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                            >
                              <Col xs={8}>
                                <p>KOCEK out amount</p>
                              </Col>
                              <Col xs={16}>
                                <p>RM {parseFloat(formatCurrency(item.order.order_amount)).toFixed(2)}</p>
                              </Col>
                            </Row>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                            >
                              <Col xs={8}>
                                <p>Message</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.message}</p>
                              </Col>
                            </Row>
                            <Row className="logs-detail-top-column" style={{ paddingTop: 16 }}>
                              <Col xs={8}>
                                <p>Status</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.new_status}</p>
                              </Col>
                            </Row>
                          </>
                        ) : null}

                        {item.action_type === 7 || item.action_type === 8 ? (
                          <>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16 }}
                            >
                              <Col xs={8}>
                                <p>Collected item</p>
                              </Col>
                              <Col xs={16}>
                                {item.order.order_details.map((product, index) => {
                                  return (
                                    <p style={index === 0 ? null : { marginTop: 16 }}>
                                      {product.product_quantity} x {product.product_name} (RM{" "}
                                      {parseFloat(formatCurrency(product.product_total_amount)).toFixed(2)})
                                    </p>
                                  );
                                })}
                              </Col>
                            </Row>
                            <Row
                              className="logs-detail-top-column"
                              style={{ borderBottom: "1px solid #D9D9D9", paddingBottom: 16, paddingTop: 16 }}
                            >
                              <Col xs={8}>
                                <p>Message</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.message}</p>
                              </Col>
                            </Row>
                            <Row className="logs-detail-top-column" style={{ paddingTop: 16 }}>
                              <Col xs={8}>
                                <p>Status</p>
                              </Col>
                              <Col xs={16}>
                                <p>{item.new_status}</p>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div>No activity yet</div>
      )}
    </Spin>
  );
};

export default OrderLog;
