import axios from "./axios";
import moment from "moment";
import { set, get } from "lodash";
import { apiCreateKocekOutToken } from "./utils";

import { TRANSACTION_URL, TRANSACTION_STATISTIC, TRANSACTION_STATISTIC_KO } from "./APILinks";

export const apiGetTransaction = (params) =>
  axios.get(TRANSACTION_URL, {
    params,
  });

export const apiSetTransactionStatus = (id, newStatus, failedDescription) =>
  axios.put(TRANSACTION_URL + "/" + id + "/status", {
    newStatus,
    description: failedDescription,
  });

export const apiBulkSetTransactionStatus = ({ transactionId, newStatus, description }) =>
  axios.put(TRANSACTION_URL + "/status", {
    newStatus,
    description,
    transactionId,
  });

export const apiSetTransactionBankInfo = (id, newBankInfo) =>
  axios.put(TRANSACTION_URL + "/" + id + "/bank-info", newBankInfo);

export const apiTransactionStatistic = async ({
  statisticBy,
  //statisticBy: ["DAILY" || "MONTHLY"]
  fromDate,
  toDate,
  storeId,
} = {}) => {
  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  const response = await axios.get(TRANSACTION_STATISTIC, {
    params: {
      statisticBy,
      fromDate: momentFromDate.toISOString(),
      toDate: momentToDate.toISOString(),
      timeZone: moment().format("Z"),
      storeId,
    },
  });

  const { data: statisticArr = [] } = response;

  // statisticArr shape : [
  //   {
  //     average,
  //     date: "2021:03:11",
  //     total_collection_RM,
  //     total_collection_RM_completed,
  //     total_collection_RM_to_bank,
  //     total_collection_RM_to_charity,
  //     total_transaction,
  //     total_transaction_completed,
  //     total_transaction_to_bank,
  //     total_transaction_to_charity,
  //   },
  // ];

  const mapStatisticBy = {
    DAILY: "days",
    MONTHLY: "months",
    WEEKLY: "weeks",
    YEARLY: "years",
  };
  // * make sure correct date format response from sv
  const mapDateFormat = {
    DAILY: "YYYY-MM-DD",
    MONTHLY: "YYYY-MM",
    WEEKLY: "YYYY-MM-DD",
    YEARLY: "YYYY",
  };

  const responseDateFormat = mapDateFormat[statisticBy];

  // fill in th missing date
  // * make sure statisticArr is sorted by date ASC

  // loop from start date to end date
  // the index of the loop should be the index of the statisticArr
  for (
    let date = momentFromDate, index = 0;
    date.isBefore(momentToDate);
    date.add(1, mapStatisticBy[statisticBy]), index++
  ) {
    //date string response from sv
    const dateString = date.format(responseDateFormat);

    //missing date the the correct index
    if (dateString !== get(statisticArr, `[${index}].date`)) {
      //add empty date row at the correct index
      statisticArr.splice(index, 0, { date: date.toISOString() });
    } else {
      //set date by moment
      set(statisticArr, `[${index}].date`, date.toISOString());
    }
  }

  return statisticArr;
};

export const apiTransactionStatisticKO = async ({
  statisticBy,
  //statisticBy: ["DAILY" || "MONTHLY"]
  fromDate,
  toDate,
  storeId,
} = {}) => {
  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  const token = await apiCreateKocekOutToken();
  const response = await axios.get(TRANSACTION_STATISTIC_KO, {
    params: {
      statisticBy,
      fromDate: momentFromDate.toISOString(),
      toDate: momentToDate.toISOString(),
      timeZone: moment().format("Z"),
      storeId,
    },
    headers: {
      Authorization: `${token.data.token_type} ${token.data.access_token}`,
    },
  });

  const { data: statisticArr = [] } = response;

  const mapStatisticBy = {
    DAILY: "days",
    MONTHLY: "months",
    WEEKLY: "weeks",
    YEARLY: "years",
  };
  // * make sure correct date format response from sv
  const mapDateFormat = {
    DAILY: "YYYY-MM-DD",
    MONTHLY: "YYYY-MM",
    WEEKLY: "YYYY-MM-DD",
    YEARLY: "YYYY",
  };

  const responseDateFormat = mapDateFormat[statisticBy];

  // fill in th missing date
  // * make sure statisticArr is sorted by date ASC

  // loop from start date to end date
  // the index of the loop should be the index of the statisticArr
  for (
    let date = momentFromDate, index = 0;
    date.isBefore(momentToDate);
    date.add(1, mapStatisticBy[statisticBy]), index++
  ) {
    //date string response from sv
    const dateString = date.format(responseDateFormat);

    //missing date the the correct index
    if (dateString !== get(statisticArr, `[${index}].date`)) {
      //add empty date row at the correct index
      statisticArr.splice(index, 0, { date: date.toISOString() });
    } else {
      //set date by moment
      set(statisticArr, `[${index}].date`, date.toISOString());
    }
  }
  return statisticArr;
};
