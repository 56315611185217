import styled from "styled-components";

const ModalTransferCreditStyled = styled.div`
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-col-24.ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-modal-footer {
    border-top: 0px;
  }
  .upload-text {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .browse-container {
    height: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: white;
    border-radius: 2px;
    border: 1px #d9d9d9 solid;
    justify-content: center;
    align-items: center;
    gap: 4;
    display: inline-flex;
  }
  .browse-button {
    text-align: center;
    color: #505050;
    font-size: 12px;
    font-weight: 500;
    word-wrap: break-word;
  }
`;

export default ModalTransferCreditStyled;
