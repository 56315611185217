import { Form, Input, Row, Col, Button, Space, Select } from "antd";
import { useTranslation } from "react-i18next";
import DateRangePicker from "src/components/antdCustom/DateRangePicker";

const FilterBar = (props) => {
  const { t } = useTranslation();
  const { loading, form, storeList } = props;

  return (
    <Form form={form} layout="horizontal" colon={false} onFinish={props.onChange} style={{ paddingBottom: 24 }}>
      <Row gutter={8}>
        <Col className="gutter-row" xs={12} lg={6} xxl={4}>
          <Form.Item name="id" label="Search" labelCol={{ span: 24 }}>
            <Input placeholder={"Transaction ID"} allowClear />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} lg={6} xxl={4}>
          <Form.Item name="dateRange" label="Date" labelCol={{ span: 24 }}>
            <DateRangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} lg={6} xxl={4}>
          <Form.Item name="merchants" label="Agent" labelCol={{ span: 24 }}>
            <Select
              mode="multiple"
              options={storeList}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
              placeholder={"All"}
            />
          </Form.Item>
        </Col>
        <Col>
          <Space>
            <Form.Item label={"\u00A0"} labelCol={{ span: 24 }} className="button">
              <Button block type="primary" htmlType="submit" loading={loading}>
                {t("search")}
              </Button>
            </Form.Item>
            <Form.Item shouldUpdate label={"\u00A0"} labelCol={{ span: 24 }} className="button">
              <Button
                block
                onClick={() => {
                  form.resetFields();
                  props.onChange();
                }}
              >
                {t("resetFilter")}
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterBar;
