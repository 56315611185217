import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionCard from "./SectionCard";
import useAPILoading from "hooks/useAPILoading";
import { fetchOrderLogs, fetchOrders } from "src/APIService/orders";
import "./styled.css";

const OrderDetails = () => {
  const [orders, setOrders] = useState();
  const [orderLogs, setOrderLogs] = useState(null);
  const [getOrders] = useAPILoading(fetchOrders);
  const [getOrderLogs, loadingOrderLogs] = useAPILoading(fetchOrderLogs);
  const location = useLocation();
  const orderId = location.state && location.state.orderId;

  const stringOrderId = ` ${orderId}`;

  const detailsOrders = () => {
    getOrders({
      order_id_or_client_name: stringOrderId,
    }).then(({ data }) => {
      getLogs(data.data[0].order_id);
      setOrders(data);
    });
  };

  const getLogs = (id) => {
    getOrderLogs(id).then(({ data }) => setOrderLogs(data.data));
  };

  useEffect(() => {
    detailsOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard
      title="Section"
      content="Content"
      orders={orders}
      orderLogs={orderLogs}
      loadingOrderLogs={loadingOrderLogs}
    />
  );
};

export default OrderDetails;
