import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const CreateButton = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginBottom: 25,
      }}
    >
      <Link to={"/stores/create"}>
        <Button type="primary" icon={<PlusOutlined />}>
          {t("addNewKiosk")}
        </Button>
      </Link>
    </div>
  );
};

export default CreateButton;
