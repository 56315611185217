import React from "react";
import { Layout } from "antd";
import FilterBar from "./FilterBar";
import AgentListingStyled from "./styled";
import TableAgent from "./TableAgent";

const { Content } = Layout;

const AgentListing = (props) => {
  const { params, setParams, loading, resData, setResData, form } = props;

  const handleFilterChange = (filter) => {
    let { pagination } = params;
    pagination = { ...pagination, current: 1 };
    //set page to 1 when change filter
    setResData();
    setParams({ ...params, filter, pagination });
  };

  const { filter, pagination } = params;

  return (
    <AgentListingStyled>
      <Content
        style={{
          padding: "24px 20px 24px 20px",
          background: "white",
          borderRadius: 8,
        }}
      >
        <FilterBar value={filter} onChange={handleFilterChange} loading={loading} form={form} />
        <TableAgent
          dataSource={resData?.data}
          pagination={{ ...pagination, total: resData?.total }}
          loading={loading}
          form={form}
        />
      </Content>
    </AgentListingStyled>
  );
};

export default AgentListing;
