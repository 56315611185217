export const STATUS_TEXT = {
  out: {
    textStatus: "Usage",
    textColor: "#837300",
    backgroundColor: "#FFF5AD",
  },
  in: {
    textStatus: "Top-up",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
};
