import React, { useEffect, useState } from "react";
import AgentStyled from "./styled";
import { Col, Form, Row, Tabs } from "antd";
import useAPILoading from "hooks/useAPILoading";
import { fetchStores } from "src/APIService/store";
import { formatCurrency } from "src/utils";
import moment from "moment";
import { getCreditHistory } from "src/APIService/credit";
import CreditHistory from "./Credit";
import CreateButton from "./CreateButton";
import AgentListing from "./AgentListing";
import ModalTransferCredit from "./ModalTransferCredit";

const { TabPane } = Tabs;

const getCreditParams = ({ filter, pagination = {} } = {}, setDateRangeStatistics) => {
  let output = {
    pageSize: pagination.pageSize,
    page: pagination.current,
  };

  if (filter) {
    const { dateRange, ...othersFilter } = filter;
    if (dateRange) {
      othersFilter.fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
      othersFilter.toDate = moment(dateRange[1]).format("YYYY-MM-DD");
    } else {
      setDateRangeStatistics({});
    }

    output = {
      ...othersFilter,
      ...output,
    };
  } else {
    setDateRangeStatistics({});
  }
  return output;
};

const getAgentParams = ({ filter, pagination = {} } = {}) => {
  const output = {
    pageSize: pagination.pageSize,
    page: pagination.current,
    filter: { ...filter, kiosk_type: "agent" },
  };

  return output;
};

const Agent = () => {
  const [resData, setResData] = useState();
  const [agentResData, setAgentResData] = useState();
  const [getCredit, loading] = useAPILoading(getCreditHistory);
  const [getStore, getStoreLoading] = useAPILoading(fetchStores);
  const [storeList, setStoreList] = useState();
  const [dateRangeStatistics, setDateRangeStatistics] = useState({});
  const [totalSales, setTotalSales] = useState();
  const [openModalTransferCredit, setOpenModalTransferCredit] = useState(false);
  const [agentParams, setAgentParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filter: {},
    sort: {},
  });
  const [params, setParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filter: {},
    sort: {},
  });

  useEffect(() => {
    getCredit(getCreditParams(params, setDateRangeStatistics)).then(({ data }) => {
      setResData(data);
      setTotalSales(data);
      setDateRangeStatistics(data.totalDateRange);
    });
  }, [params, getCredit]);

  useEffect(() => {
    getStore(getAgentParams(agentParams)).then(({ data }) => {
      setAgentResData(data);
    });
  }, [agentParams, getStore]);

  useEffect(() => {
    allAgentStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = Form.useForm();

  const allAgentStores = () => {
    getStore({ filter: { kiosk_type: "agent" } })
      .then(({ data }) => {
        const { data: storeListData } = data;
        if (storeListData) {
          const mappedStoreList = storeListData.map(({ store_name, id }) => ({
            value: id,
            label: store_name,
          }));
          setStoreList(mappedStoreList);
        }
      })
      .catch((error) => console.error("Store API Error:", error));
  };

  const onChangeTab = () => {
    let { pagination } = params;
    pagination = { ...pagination, current: 1 };
    setResData();
    setAgentResData();
    setAgentParams({ ...agentParams, filter: {}, pagination });
    setParams({ ...params, filter: {}, pagination });
    form.resetFields();
  };

  return (
    <AgentStyled>
      <div className="new-order-button-container">
        <div style={{ overflow: "hidden" }}>
          <Row className="sales-statistic-container" gutter={20}>
            {dateRangeStatistics && Object.keys(dateRangeStatistics).length > 0 ? (
              <>
                <Col xs={12} lg={6}>
                  <div>
                    {dateRangeStatistics?.fromDate} to {dateRangeStatistics?.toDate}:{" "}
                    {dateRangeStatistics?.topupSales || 0} top-up
                  </div>
                  <div className="amount">RM{formatCurrency((dateRangeStatistics?.topupAmount || 0).toFixed(2))}</div>
                </Col>
                <Col xs={12} lg={6}>
                  <div>
                    {dateRangeStatistics?.fromDate} to {dateRangeStatistics?.toDate}: {dateRangeStatistics?.sales || 0}{" "}
                    Usage
                  </div>
                  <div className="amount">RM{formatCurrency((dateRangeStatistics?.amount || 0).toFixed(2))}</div>
                </Col>
              </>
            ) : (
              <>
                <Col xs={12} lg={6} className="sales-statistic-col">
                  <div>Agents</div>
                  <div className="amount">{storeList?.length} Agents</div>
                </Col>
                <Col xs={12} lg={6} className="sales-statistic-col">
                  <div>Top-up: This week</div>
                  <div className="amount">
                    RM{formatCurrency((totalSales?.totalCurrentWeek?.topupAmount || 0).toFixed(2))}
                  </div>
                </Col>
                <Col xs={12} lg={6} className="sales-statistic-col">
                  <div>Top-up: This month</div>
                  <div className="amount">
                    RM{formatCurrency((totalSales?.totalThisMonth?.topupAmount || 0).toFixed(2))}
                  </div>
                </Col>
                <Col xs={12} lg={6} className="sales-statistic-col">
                  <div>Usage: All</div>
                  <div className="amount">RM{formatCurrency((totalSales?.totalUsage?.amount || 0).toFixed(2))}</div>
                </Col>
              </>
            )}
          </Row>
        </div>
        <Tabs
          defaultActiveKey="agents"
          onChange={onChangeTab}
          tabBarExtraContent={<CreateButton setOpenModalTransferCredit={setOpenModalTransferCredit} />}
        >
          <TabPane tab={`All Agents`} key="agents">
            <AgentListing
              params={agentParams}
              setParams={setAgentParams}
              form={form}
              resData={agentResData}
              setResData={setAgentResData}
              loading={getStoreLoading}
            />
          </TabPane>
          <TabPane tab={`Credit`} key="credit">
            <CreditHistory
              storeList={storeList}
              params={params}
              setParams={setParams}
              form={form}
              resData={resData}
              setResData={setResData}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </div>
      <ModalTransferCredit
        openModalTransferCredit={openModalTransferCredit}
        setOpenModalTransferCredit={setOpenModalTransferCredit}
        agentList={storeList}
        onChange={onChangeTab}
      />
    </AgentStyled>
  );
};

export default Agent;
