import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import moment from "moment";
import { get } from "lodash";

import { typeStatisticBy } from "../constant";
import { formatCurrencyWith0 } from "utils";
const renderZeroAsDefault = (value = 0) => value;
const renderMoney = (value = 0) => formatCurrencyWith0(value);

export const dataIndex = {
  totalTransaction: "total_transaction",
  totalServiceFee: "total_service_fee_RM",
  totalRM: "total_sale_RM",
};

const useColumns = ({ statisticBy } = {}) => {
  const { t } = useTranslation();

  let statisticFormat = "LL";
  if (statisticBy === typeStatisticBy.monthly) {
    statisticFormat = "MMM/YYYY";
  }
  if (statisticBy === typeStatisticBy.yearly) {
    statisticFormat = "YYYY";
  }

  return useMemo(
    () =>
      [
        statisticBy === typeStatisticBy.daily && {
          title: t("week"),
          key: "week",
          dataIndex: "date",
          fixed: "left",
          width: 55,
          className: "background-gray",
          render: (date) => moment(date).format("w"),
        },
        statisticBy === typeStatisticBy.daily && {
          title: t("day"),
          key: "day",
          dataIndex: "date",
          fixed: "left",
          width: 60,
          className: "background-gray",
          render: (date) => moment(date).format("ddd"),
        },

        {
          title: t("date"),
          dataIndex: "date",
          key: "date",
          fixed: "left",
          width: 130,
          className: "background-gray",
          render: (date) => moment(date).format(statisticFormat),
        },
        {
          title: t("statisticTb.totalTransaction"),
          dataIndex: dataIndex.totalTransaction,
          key: "total_transaction",
          render: renderZeroAsDefault,
          showingInFooter: true,
        },
        {
          title: t("statisticTb.totalSales"),
          dataIndex: dataIndex.totalRM,
          key: "total_sale_RM",
          render: renderMoney,
          showingInFooter: true,
        },
        {
          title: t("statisticTb.totalServiceFee"),
          dataIndex: dataIndex.totalServiceFee,
          key: "total_service_fee_RM",
          render: renderMoney,
          showingInFooter: true,
        },

        {
          title: t("statisticTb.average"),
          key: "average",
          render: (rowData) => {
            const average = get(rowData, dataIndex.totalRM, 0) / get(rowData, dataIndex.totalTransaction, 1);

            return renderMoney(+Number.parseFloat(average).toFixed(2));
          },
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, statisticBy]
  );
};

export default useColumns;
