import { Form, Input, Row, Col, Button, Space, Select } from "antd";
import { useTranslation } from "react-i18next";

const FilterBar = (props) => {
  const agentStatus = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];
  const { t } = useTranslation();
  const { loading, form } = props;

  return (
    <Form form={form} layout="horizontal" colon={false} onFinish={props.onChange} style={{ paddingBottom: 24 }}>
      <Row gutter={8}>
        <Col className="gutter-row" xs={12} lg={6} xxl={4}>
          <Form.Item name="store_name" label="Search" labelCol={{ span: 24 }}>
            <Input placeholder={"Search location or agent"} allowClear />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} lg={6} xxl={4}>
          <Form.Item name="status" label="Status" labelCol={{ span: 24 }}>
            <Select defaultValue={"All"} options={agentStatus} filterOption={false} />
          </Form.Item>
        </Col>
        <Col>
          <Space>
            <Form.Item label={"\u00A0"} labelCol={{ span: 24 }} className="button">
              <Button block type="primary" htmlType="submit" loading={loading}>
                {t("search")}
              </Button>
            </Form.Item>
            <Form.Item shouldUpdate label={"\u00A0"} labelCol={{ span: 24 }} className="button">
              <Button
                block
                onClick={() => {
                  form.resetFields();
                  props.onChange();
                }}
              >
                {t("resetFilter")}
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterBar;
