import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/antdCustom/Modal";
import { TRANSACTION_STATUS } from "./columns";
import { Input, Radio, Select, Space } from "antd";

const rowStyle = {
  display: "flex",
  flexDirection: "row",
};
const labelStyle = {
  width: "200px",
};

const FAILURE_DESCRIPTION_OPTION = {
  OTHER: "Other",
  INVALID_ACCOUNT_NUMBER: "Invalid account number",
};

const ModalChangeStatus = (props) => {
  const { visible, transaction, onCancel, onOk } = props;
  const { id: transactionId, amount, status } = transaction;
  const { t } = useTranslation();
  const [newStatus, setNewStatus] = useState(null);
  const [failureDescriptionOption, setFailureDescriptionOption] = useState(null);
  const [customFailureDescriptionMsg, setCustomFailureDescriptionMsg] = useState(null);
  const getOptions = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.WAITING_FOR_TRANSFER:
        return [
          {
            value: TRANSACTION_STATUS.PENDING_BANK_APPROVAL,
            label: t("pendingBankApproval"),
          },
          {
            value: TRANSACTION_STATUS.VOID,
            label: t("voidTransaction"),
          },
        ];
      case TRANSACTION_STATUS.PENDING_BANK_APPROVAL:
        return [
          { value: TRANSACTION_STATUS.DONE, label: t("paid") },
          { value: TRANSACTION_STATUS.FAILED, label: t("failed") },
        ];
      case TRANSACTION_STATUS.FAILED:
        return [
          {
            value: TRANSACTION_STATUS.WAITING_FOR_TRANSFER,
            label: t("waitingTransfer"),
          },
        ];
      default:
        return [];
    }
  };

  const getTranslateStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.WAITING_FOR_TRANSFER:
        return t("waitingTransfer");
      case TRANSACTION_STATUS.PENDING_BANK_APPROVAL:
        return t("pendingBankApproval");
      case TRANSACTION_STATUS.FAILED:
        return t("failed");
      default:
        return status;
    }
  };

  const onChooseStatus = (value) => {
    setNewStatus(value);
  };

  const resetState = () => {
    setNewStatus(null);
    setFailureDescriptionOption(null);
    setCustomFailureDescriptionMsg(null);
  };

  const onSave = () => {
    const failedDescription =
      failureDescriptionOption === FAILURE_DESCRIPTION_OPTION.OTHER
        ? customFailureDescriptionMsg
        : failureDescriptionOption;
    onOk(transactionId, newStatus, failedDescription);
    resetState();
  };

  const closeModal = () => {
    resetState();
    onCancel();
  };

  const onSelectReason = (e) => {
    setFailureDescriptionOption(e.target.value);
  };
  return (
    <Modal
      title={t("updateTransactionStatus")}
      visible={visible}
      onOk={onSave}
      onCancel={closeModal}
      okText={t("save")}
      cancelText={t("cancel")}
      okButtonProps={{
        disabled:
          !newStatus ||
          (newStatus === TRANSACTION_STATUS.FAILED &&
            (!failureDescriptionOption ||
              (failureDescriptionOption === FAILURE_DESCRIPTION_OPTION.OTHER && !customFailureDescriptionMsg))),
      }}
      destroyOnClose={true}
    >
      <div>
        <div style={rowStyle}>
          <p style={labelStyle}>{t("transactionIdLabel")}</p>
          <p>{transactionId}</p>
        </div>
        <div style={rowStyle}>
          <p style={labelStyle}>{t("amountTransferred")}</p>
          <p>{amount}</p>
        </div>
        <div style={rowStyle}>
          <p style={labelStyle}>{t("statusLabel")}</p>
          <p>{getTranslateStatus(status)}</p>
        </div>
        <div style={rowStyle}>
          <p style={labelStyle}>{t("newStatus")}</p>
          <Select
            style={{ flex: 1, marginBottom: "5px" }}
            placeholder={<p>{t("pleaseSelect")}</p>}
            onChange={onChooseStatus}
            options={getOptions(status)}
            value={newStatus}
          />
        </div>
        {newStatus === TRANSACTION_STATUS.FAILED && (
          <div style={{ ...rowStyle, marginTop: "10px" }}>
            <p style={labelStyle}>{t("description")}</p>
            <div style={{ flex: 1 }}>
              <Radio.Group onChange={onSelectReason} value={failureDescriptionOption}>
                <Space direction="vertical">
                  <Radio value={FAILURE_DESCRIPTION_OPTION.INVALID_ACCOUNT_NUMBER}>{t("invalidAccountNumber")}</Radio>
                  <Radio value={FAILURE_DESCRIPTION_OPTION.OTHER}>{t("other")}</Radio>
                </Space>
              </Radio.Group>
              {failureDescriptionOption === FAILURE_DESCRIPTION_OPTION.OTHER && (
                <Input.TextArea
                  style={{
                    marginTop: "5px",
                  }}
                  value={customFailureDescriptionMsg}
                  onChange={(e) => setCustomFailureDescriptionMsg(e.target.value)}
                  placeholder={t("pleaseInsertYourReason")}
                  autoSize={{ minRows: 3 }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalChangeStatus;
