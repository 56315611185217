import React, { useCallback, useEffect, useState } from "react";
import OrderListingStyled from "./styled";
import { Col, Form, Row, Tabs } from "antd";
import AllOrdersList from "./AllOrder";
import FilteredOrdersList from "./OrderStatus";
import useAPILoading from "hooks/useAPILoading";
import { fetchOrders } from "src/APIService/orders";
import { fetchStores } from "src/APIService/store";
import { formatCurrency } from "src/utils";
import moment from "moment";

const { TabPane } = Tabs;

const getOrdersParams = ({ filter, merchant_id, order_status, pagination = {} } = {}, setDateRangeStatistics) => {
  let output = {
    merchant_id,
    pageSize: pagination.pageSize,
    page: pagination.current,
    order_status,
  };

  if (filter) {
    const { dateRange, ...othersFilter } = filter;
    if (dateRange) {
      othersFilter.fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
      othersFilter.toDate = moment(dateRange[1]).format("YYYY-MM-DD");
    } else {
      setDateRangeStatistics({});
    }

    output = {
      ...othersFilter,
      ...output,
    };
  } else {
    setDateRangeStatistics({});
  }

  return output;
};

const OrderListing = () => {
  const [orders, setOrders] = useState();
  const [getOrders, loading] = useAPILoading(fetchOrders);
  const [getStore] = useAPILoading(fetchStores);
  const [storeList, setStoreList] = useState();
  const [dateRangeStatistics, setDateRangeStatistics] = useState({});
  const [totalOrders, setTotalOrders] = useState();
  const [params, setParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filter: {},
    sort: {},
  });
  const [form] = Form.useForm();

  useEffect(() => {
    allStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allOrders = useCallback(() => {
    getOrders(getOrdersParams(params, setDateRangeStatistics)).then(({ data }) => {
      setOrders(data);
      setTotalOrders(data);
      setDateRangeStatistics(data.totalDateRange);
    });
  }, [getOrders, params]);

  useEffect(() => {
    allOrders();
  }, [params, allOrders]);

  const allStores = () => {
    getStore()
      .then(({ data }) => {
        const { data: storeListData } = data;
        if (storeListData) {
          const mappedStoreList = storeListData.map(({ store_name, id }) => ({
            value: id,
            label: store_name,
          }));
          setStoreList(mappedStoreList);
        }
      })
      .catch((error) => console.error("Store API Error:", error));
  };

  const onChangeTab = (status) => {
    let { pagination } = params;
    pagination = { ...pagination, current: 1 };
    setOrders();
    setParams({ ...params, filter: {}, order_status: status, pagination });
    form.resetFields();
  };

  return (
    <OrderListingStyled>
      <div className="new-order-button-container">
        <div style={{ overflow: "hidden" }}>
          <Row className="order-statistic-container" gutter={20}>
            {Object.keys(dateRangeStatistics).length > 0 ? (
              <Col xs={12}>
                <div>
                  {dateRangeStatistics?.fromDate} to {dateRangeStatistics?.toDate}
                  {dateRangeStatistics?.order_status
                    ? `, ${(
                        dateRangeStatistics?.order_status[0].toUpperCase() +
                        dateRangeStatistics?.order_status.substring(1)
                      ).replaceAll("_", " ")}`
                    : null}
                  {dateRangeStatistics?.order_type
                    ? `, ${(
                        dateRangeStatistics?.order_type[0].toUpperCase() + dateRangeStatistics?.order_type.substring(1)
                      ).replaceAll("_", " ")}`
                    : null}{" "}
                  : {dateRangeStatistics?.sales || 0} {dateRangeStatistics?.sales > 1 ? "sales" : "sale"}
                </div>
                <div className="amount">RM{formatCurrency((dateRangeStatistics?.amount || 0).toFixed(2))}</div>
              </Col>
            ) : (
              <>
                <Col xs={12} lg={6}>
                  <div>
                    Today: {totalOrders?.totalToday?.sales || 0} {totalOrders?.totalToday?.sales > 1 ? "sales" : "sale"}
                  </div>
                  <div className="amount">RM{formatCurrency((totalOrders?.totalToday?.amount || 0).toFixed(2))}</div>
                </Col>
                <Col xs={12} lg={6}>
                  <div>
                    Yesterday: {totalOrders?.totalYesterday?.sales || 0}{" "}
                    {totalOrders?.totalYesterday?.sales > 1 ? "sales" : "sale"}
                  </div>
                  <div className="amount">
                    RM{formatCurrency((totalOrders?.totalYesterday?.amount || 0).toFixed(2))}
                  </div>
                </Col>
                <Col xs={12} lg={6} className="order-statistic-col">
                  <div>
                    This week: {totalOrders?.totalCurrentWeek?.sales || 0}{" "}
                    {totalOrders?.totalCurrentWeek?.sales > 1 ? "sales" : "sale"}
                  </div>
                  <div className="amount">
                    RM{formatCurrency((totalOrders?.totalCurrentWeek?.amount || 0).toFixed(2))}
                  </div>
                </Col>
                <Col xs={12} lg={6} className="order-statistic-col">
                  <div>
                    This month: {totalOrders?.totalThisMonth?.sales || 0}{" "}
                    {totalOrders?.totalThisMonth?.sales > 1 ? "sales" : "sale"}
                  </div>
                  <div className="amount">
                    RM{formatCurrency((totalOrders?.totalThisMonth?.amount || 0).toFixed(2))}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
        <Tabs defaultActiveKey="" onChange={onChangeTab}>
          <TabPane tab={`All orders (${totalOrders?.totalOrders || 0})`} key="">
            <AllOrdersList
              loading={loading}
              resData={orders}
              setResData={setOrders}
              params={params}
              setParams={setParams}
              storeList={storeList}
              form={form}
            />
          </TabPane>
          <TabPane tab={`Received (${totalOrders?.totalReceived || 0})`} key="received">
            <FilteredOrdersList
              loading={loading}
              category="received"
              storeList={storeList}
              resData={orders}
              setResData={setOrders}
              params={params}
              setParams={setParams}
              form={form}
            />
          </TabPane>
          <TabPane tab={`Ready for collection (${totalOrders?.totalCollection || 0})`} key="ready_for_collection">
            <FilteredOrdersList
              loading={loading}
              category="ready_for_collection"
              storeList={storeList}
              resData={orders}
              setResData={setOrders}
              params={params}
              setParams={setParams}
              form={form}
            />
          </TabPane>
          <TabPane tab={`Collected (${totalOrders?.totalCollected || 0})`} key="collected">
            <FilteredOrdersList
              loading={loading}
              category="collected"
              storeList={storeList}
              resData={orders}
              setResData={setOrders}
              params={params}
              setParams={setParams}
              form={form}
            />
          </TabPane>
          <TabPane tab={`Completed (${totalOrders?.totalCompleted || 0})`} key="completed">
            <FilteredOrdersList
              loading={loading}
              category="completed"
              storeList={storeList}
              resData={orders}
              setResData={setOrders}
              params={params}
              setParams={setParams}
              form={form}
            />
          </TabPane>
        </Tabs>
      </div>
    </OrderListingStyled>
  );
};

export default OrderListing;
