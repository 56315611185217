import { React } from "react";
import { Tabs } from "antd";
import BulkUpdateStatusButton from "./BulkUpdateStatusButton";

const { TabPane } = Tabs;

export default function TabTransactionStatus({
  onChange,
  status,
  selectedKeys,
  fetchTransaction,
  setSelectedKeys,
  setSelectedItems,
}) {
  const isShow = status !== "DONE";
  return (
    <Tabs
      tabBarExtraContent={
        isShow && (
          <BulkUpdateStatusButton
            status={status}
            selectedKeys={selectedKeys}
            fetchTransaction={fetchTransaction}
            setSelectedKeys={setSelectedKeys}
            setSelectedItems={setSelectedItems}
          />
        )
      }
      defaultActiveKey=""
      activeKey={status}
      onChange={onChange}
    >
      <TabPane tab="Waiting for transfer" key="WAITING_FOR_TRANSFER" />
      <TabPane tab="Pending bank approval" key="PENDING_BANK_APPROVAL" />
      <TabPane tab="Paid" key="DONE" />
      <TabPane tab="Failed" key="FAILED" />
      <TabPane tab="Void" key="VOID" />
    </Tabs>
  );
}
