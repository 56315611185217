import React from "react";
import { Button } from "antd";
import moment from "moment";
import get from "lodash/get";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { EditFilled, IdcardFilled, UnorderedListOutlined } from "@ant-design/icons";

export const TRANSACTION_STATUS = {
  CONFIRMED: "CONFIRMED",
  WAITING_FOR_TRANSFER: "WAITING_FOR_TRANSFER",
  PENDING_BANK_APPROVAL: "PENDING_BANK_APPROVAL",
  DONE: "DONE",
  FAILED: "FAILED",
  VOID: "VOID",
};

const EditedTextStyle = styled.div`
  color: #909090;
  font-size: 15px;
`;

const EditedColumn = styled.div`
  text-align: center;
  .btn {
    width: 160px;
  }
`;

const getColumnsTranslated = (t, onClickUpdateStatus, onClickEditBankInfo) => [
  {
    title: t("transactionId"),
    dataIndex: "id",
    key: "id",
    width: "120px",
  },
  {
    title: t("phoneNumber"),
    dataIndex: "client_phone",
    key: "client_phone",
  },
  {
    title: t("customerName"),
    dataIndex: "client_full_name",
    key: "client_full_name",
  },
  {
    title: t("email"),
    dataIndex: "client_email",
    key: "client_email",
  },
  {
    title: t("coinAmountRM"),
    dataIndex: "transaction_detail_original_amount",
    key: "transaction_detail_original_amount",
    sorter: true,
    width: "120px",
    render: (value) => value && value.toFixed(2),
  },
  {
    title: t("depositAmount"),
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    render: (value) => value && value.toFixed && value.toFixed(2),
  },
  {
    title: t("voucherIncentive"),
    sorter: true,
    render: (data) =>
      data.transaction_detail_send_to === "VOUCHER" && data.transaction_detail_voucher_amount ? (
        <span style={{ color: "green" }}>+ {(data.transaction_detail_voucher_amount - data.amount).toFixed(2)}</span>
      ) : (
        "-"
      ),
  },
  {
    title: t("feeAmount"),
    dataIndex: "transaction_detail_fee",
    key: "transaction_detail_fee",
    sorter: true,
    width: "120px",
    render: (value) => value && value.toFixed(2),
  },
  {
    title: t("storeName"),
    dataIndex: "store_name",
    key: "store_name",
  },
  {
    title: t("status"),
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: (value) => {
      switch (value) {
        case TRANSACTION_STATUS.WAITING_FOR_TRANSFER:
          return t("waitingTransfer");
        case TRANSACTION_STATUS.CONFIRMED:
          return t("coinReceived");
        case TRANSACTION_STATUS.PENDING_BANK_APPROVAL:
          return t("pendingBankApproval");
        case TRANSACTION_STATUS.FAILED:
          return t("failed");
        case TRANSACTION_STATUS.VOID:
          return t("voidTransaction");
        case TRANSACTION_STATUS.DONE:
          return <span style={{ color: "#50ad54", fontSize: "20px" }}>{t("paid")}</span>;
        default:
          return value;
      }
    },
  },
  {
    title: t("sendTo"),
    render: (data) => {
      return data.transaction_detail_send_to === "BANK" ? t("bank") : data.transaction_detail_send_to;
    },
  },
  {
    title: t("bankName"),
    dataIndex: "bank_name",
    key: "bankName",
  },
  {
    title: t("bankAccountName"),
    dataIndex: "transaction_detail_bank_account_name",
    key: "bankAccountName",
  },
  {
    title: t("bankAccountNumber"),
    dataIndex: "transaction_detail_bank_account_number",
    key: "bankAccountNumber",
  },
  {
    title: t("createdBy"),
    dataIndex: "merchant_account_email",
    key: "merchant_account_email",
  },
  {
    title: t("depositTimestamp"),
    dataIndex: "created_at",
    key: "created_at",
    width: "150px",
    render: (date) => moment(date).format("LLL"),
  },
  {
    title: t("lastEditByAdmin"),
    dataIndex: "recent_edit_admin_name",
    key: "recent_edit_admin_name",
    width: "150px",
  },
  {
    title: t("action"),
    key: "action",
    width: "200px",
    showOnCSV: false,
    resizable: false,
    fixed: "right",
    render: (record) => {
      const status = get(record, "status");
      const sendTo = get(record, "transaction_detail_send_to");
      const edited = get(record, "recent_edit_admin_name");
      if (
        status === TRANSACTION_STATUS.WAITING_FOR_TRANSFER ||
        status === TRANSACTION_STATUS.PENDING_BANK_APPROVAL ||
        status === TRANSACTION_STATUS.FAILED
      ) {
        return (
          <EditedColumn>
            {!!edited && <EditedTextStyle>*{t("edited")}</EditedTextStyle>}
            {sendTo === "BANK" &&
              (status === TRANSACTION_STATUS.WAITING_FOR_TRANSFER || status === TRANSACTION_STATUS.FAILED) && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickEditBankInfo(record);
                  }}
                  className="btn"
                  style={{ margin: "0px 0px 5px 0px" }}
                >
                  <IdcardFilled />
                  {t("editBankInfo")}
                </Button>
              )}
            <Button
              className="btn"
              style={{ margin: "0px 0px 5px 0px" }}
              onClick={(e) => {
                e.stopPropagation();
                onClickUpdateStatus(record);
              }}
            >
              <EditFilled />
              {t("updateStatus")}
            </Button>
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={{
                pathname: "/coin-conversion-log",
                search: `?filter={"transactionId":"${record.id}"}`,
              }}
            >
              <Button className="btn">
                <UnorderedListOutlined />
                {t("viewLogs")}
              </Button>
            </Link>
          </EditedColumn>
        );
      }
      return (
        <EditedColumn>
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={{
              pathname: "/coin-conversion-log",
              search: `?filter={"transactionId":"${record.id}"}`,
            }}
          >
            <Button className="btn">
              <UnorderedListOutlined />
              {t("viewLogs")}
            </Button>
          </Link>
        </EditedColumn>
      );
    },
  },
];

export default getColumnsTranslated;
