export const STATUS_TEXT = {
  received: {
    textStatus: "Received",
    textColor: "#837300",
    backgroundColor: "#FFF5AD",
  },
  unpaid: {
    textStatus: "Unpaid",
    textColor: "#837300",
    backgroundColor: "#FFF5AD",
  },
  paid: {
    textStatus: "Paid",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
  completed: {
    textStatus: "Completed",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
  failed: {
    textStatus: "Failed",
    textColor: "#D9534F",
    backgroundColor: "#F7DDDC",
  },
  ready_for_collection: {
    textStatus: "Ready for collection",
    textColor: "#3FBAFF",
    backgroundColor: "#3FBAFF1A",
  },
  cancelled: {
    textStatus: "Cancelled",
    textColor: "#909090",
    backgroundColor: "#ECECEC",
  },
  pending_payment: {
    textStatus: "Pending payment",
    textColor: "#909090",
    backgroundColor: "#ECECEC",
  },
  collected: {
    textStatus: "Collected",
    textColor: "#3FBAFF",
    backgroundColor: "#3FBAFF1A",
  },
};
