import styled from "styled-components";

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;

  .credit-details-title {
    font-size: 18px;
    line-height: 21.09px;
    font-weight: 500;
  }

  .details-container {
    background-color: #f4f4f7;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;

    .top-section {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 0px;
    }

    .transaction-id {
      color: #606060;
    }

    .transaction-amount {
      color: #000000;
      font-weight: 500;
      font-size: 24px;
    }

    .transaction-date {
      color: #909090;
      font-size: 12px;
    }

    .store-name {
      font-size: 18px;
      color: #303030;
      font-weight: 500;
    }

    .chip {
      padding: 6px 16px 6px 16px;
      border-radius: 100px;
      width: fit-content;
      font-size: 12px;
      font-weight: 500;
    }

    .client-details {
      margin-top: 10px;
    }

    .icon {
      padding-left: 4px;
    }
  }

  .admin-details-container {
    margin-top: 16px;

    .title {
      color: #303030;
    }

    .email {
      color: #606060;
      font-size: 13px;
    }

    p {
      margin-bottom: 0px;
    }
  }

  .button {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    .button-row {
      flex-direction: column-reverse;
    }
  }
`;

export default ModalStyled;
