import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const CreateButton = ({ setOpenModalTransferCredit }) => {
  const { t } = useTranslation();
  return (
    <Space
      style={{
        flexDirection: "row",
      }}
    >
      <Button type="default" onClick={() => setOpenModalTransferCredit(true)} icon={<PlusOutlined />}>
        {t("addCredit")}
      </Button>
      <Link to={"/agents/create"}>
        <Button type="primary" icon={<PlusOutlined />}>
          {t("addNewAgent")}
        </Button>
      </Link>
    </Space>
  );
};

export default CreateButton;
