import React, { useMemo } from "react";
import Table from "src/components/antdCustom/Table";
import { useHistory } from "react-router-dom";
import { FEE_TYPE } from "src/variables";
import { Tooltip } from "antd";

const getColumnsTranslated = () => [
  {
    title: "Agent ID and Code",
    key: "id",
    fixed: "left",
    render: (record) => {
      const locationInitials = record.store_name
        .split(" ")
        .map(([initial]) => initial.toUpperCase())
        .join("");
      return (
        <div>
          <p
            style={{
              fontSize: 14,
              color: "#1551B0",
            }}
          >
            {record.id}
          </p>
          <p
            style={{
              fontSize: 14,
              marginBottom: 0,
            }}
          >
            {locationInitials}
          </p>
        </div>
      );
    },
    width: "200px",
  },
  {
    title: "Name & email address",
    key: "name_and_email",
    render: (record) => {
      return (
        <div>
          <p
            style={{
              fontSize: 14,
            }}
          >
            {record.store_name}
          </p>
          <p
            style={{
              fontSize: 14,
              marginBottom: 0,
              color: "#6C757D",
            }}
          >
            {record.email}
          </p>
        </div>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: "250px",
    render: (value) => (
      <p
        style={{
          fontSize: 14,
          marginBottom: 0,
          color: "#505050",
        }}
      >
        {value}
      </p>
    ),
  },
  {
    title: "Credit balance (RM)",
    dataIndex: "current_credit",
    key: "type",
    render: (value) => value && value.toFixed && value.toFixed(2),
  },
  {
    title: "KI Service fee",
    dataIndex: "active_fee",
    key: "active_fee",
    render: (active_fee) => {
      return (
        active_fee && (
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: active_fee.feeTiers
                    ?.map((tier, idx) => {
                      const displayFeeValue =
                        tier.feeType === FEE_TYPE.PERCENTAGE ? `${tier.feeValue * 100}%` : `RM ${tier.feeValue} `;
                      const maxTierAmount =
                        idx <= active_fee.feeTiers.length - 2 ? active_fee.feeTiers[idx + 1].minTierAmount - 0.01 : "";
                      return idx === active_fee.feeTiers.length - 1
                        ? `<span>From RM ${tier.minTierAmount}</span>  <span style="text-align:right; float:right;margin-left:10px">${displayFeeValue}</span><br>`
                        : `<span>RM ${tier.minTierAmount}  - RM ${maxTierAmount}</span> <span style="text-align:right; float:right;margin-left:10px">${displayFeeValue}</span><br>`;
                    })
                    .join(""),
                }}
              />
            }
            overlayStyle={{ whiteSpace: "pre-line" }}
          >
            <div
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "2px",
                paddingBottom: "2px",
                margin: "auto 0",
                background: active_fee.type === "global" ? "#E1EFFF" : "#F7E2FF",
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: active_fee.type === "global" ? "#1077ED" : "#8E44AD",
                  fontSize: "14px",
                  fontWeight: "600",
                  wordWrap: "break-word",
                  textAlign: "right", // Aligning to the most right
                }}
              >
                {active_fee.type === "global" ? "Default" : "Custom"}
              </div>
            </div>
          </Tooltip>
        )
      );
    },
  },
  {
    title: "Date created",
    dataIndex: "created_on",
    key: "created_on",
    render: (value) => value,
  },
];

const TableAgent = (props) => {
  const { dataSource, loading, onChange, pagination } = props;
  const history = useHistory();
  const columns = useMemo(() => getColumnsTranslated(), []);

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        scroll={{ x: 1400, y: "calc(100vh - 320px)" }}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push("/agents/" + record.id);
            },
            style: { cursor: "pointer" },
          };
        }}
      />
    </>
  );
};

export default TableAgent;
