import { Form, Input, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const FilterBar = (props) => {
  const { initialValues, onFinish } = props;
  const { t } = useTranslation();
  const { loading } = props;
  return (
    <Form layout="horizontal" initialValues={initialValues} onFinish={onFinish}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          margin: -10,
          flexWrap: "wrap",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Form.Item
          name="store_name"
          label={t("search")}
          style={{ flex: 1, padding: 10, maxWidth: 400 }}
          // normalize={(value) => (value !== -1 ? value : undefined)}
        >
          <Input allowClear placeholder={t("searchStore")} />
        </Form.Item>
        <Form.Item
          name="kiosk_type"
          label={t("kioskType")}
          style={{ flex: 1, padding: 10, maxWidth: 400 }}
          // normalize={(value) => (value !== -1 ? value : undefined)}
        >
          <Select style={{ width: "100%" }} defaultValue="">
            <Option value="">All</Option>
            <Option value="permanent">Permanent Kiosk</Option>
            <Option value="flexi">Flexi Kiosk</Option>
            <Option value="mobile">Mobile Kiosk</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ paddingTop: 10 }}>
          <Button
            htmlType="submit"
            style={{ width: "100%", borderColor: "#1890ff", borderWidth: 2 }}
            loading={loading}
            icon={<SearchOutlined />}
          >
            {t("search")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default FilterBar;
